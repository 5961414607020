* {
  margin: 0px;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-text: rgb(136, 101, 255);
  --color-text-hover: rgba(136, 101, 255, 0.8);

  --color-text-2: rgb(255, 111, 165);
  --color-text-2-hover: rgba(255, 111, 165, 0.8);

  --color-bkgrnd-pink: rgb(251, 158, 196);
  --color-bkgrnd-yellow: rgb(255, 218, 192);

  --color-bkgrnd-salmon: rgb(255, 199, 186);
  --color-bkgrnd-salmon-hover: rgba(255, 199, 186, 0.4);
}

a {
  text-decoration: none;
  color: var(--color-text-2 );
}
a:hover {
  color: var(--color-text );
}
